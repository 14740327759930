@import 'variables';
@import 'reset';

main {
	width: 100vw;
	min-height: 100vh;
	overflow: hidden;
	background-color: $pink;
	color: $blue;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
}

#header {
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	position: absolute;
	z-index: 1;
	top: 0;
	left: 50%;
	padding: responsive(20, 40);
	transform: translateX(-50%);
	a, button {
		font-size: responsive(14, 16);
		display: inline-block;
		background-color: #fadcff;
		padding: 2px;
	}
	.menu {
		display: flex;
		button {
			margin: 0 0 0 20px;
		}
	}
}

#wrap {
	padding: responsive(20, 40);
	position: relative;
	min-height: 100vh;
	z-index: 1;
	h1 {
		text-align: center;
		text-transform: uppercase;
		font-weight: 900;
		font-size: responsive(48, 144);
		line-height: responsive(48, 144);
		-webkit-text-stroke: responsive(2, 4) $blue;
		color: transparent;
		position: absolute;
	  top: 50%;
	  left: 50%;
	  transform: translate(-50%, -50%);
		color: #fadcff;
	}
}

#footer {
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	position: absolute;
	z-index: 1;
	bottom: 0;
	left: 50%;
	padding: responsive(20, 40);
	transform: translateX(-50%);
	a {
		display: block;
		margin: 0 responsive(10, 20);
		font-size: responsive(14, 16);
		position: relative;
		display: inline-block;
		background-color: #fadcff;
		padding: 2px;
		&:before {
			content: '';
			background-color: $blue;
			width: 6px;
			height: 6px;
			border-radius: 100%;
			position: absolute;
			top: 50%;
			left: responsive(-14, -24);
			transform: translateY(-35%);
		}
		&:first-child {
			&::before {
				display: none;
			}
		}
	}
}

#modal {
	z-index: 2;
	background-color: $blue;
	padding: responsive(20, 40);
	position: fixed;
	top: 0;
	right: -500px;
	max-width: 500px;
	height: 100vh;
	transition: right .6s ease;
	overflow-y: scroll;
	button {
		color: $pink;
		font-size: 50px;
		line-height: .5;
		transform: rotate(45deg);
		position: fixed;
	}
	p {
		color: $pink;
		font-size: 16px;
		margin: 10px 0 0;
		line-height: 1.5;
	}
	img {
		display: block;
		margin: 40px auto 20px;
		width: 100%;
		max-width: 55%;
		overflow: hidden;
		border-radius: 100%;
	}
	a {
		color: $pink;
		text-decoration: underline;
	}
	h2 {
		color: $pink;
		margin: 20px 0;
		text-align: center;
	}
	&.active {
		right: 0%;
	}
}

#modal-project {
	z-index: 2;
	background-color: $pink;
	padding: responsive(40, 100);
	position: fixed;
	top: 0;
	right: -100vw;
	width: 100vw;
	height: 100vh;
	transition: right .6s ease;
	overflow-y: scroll;
	a {
		color: $blue;
		display: block;
		width: fit-content;
		font-weight: 500;
		line-height: 1.2;
		margin: 0 0 responsive(20, 80);
		max-width: 80%;
		position: relative;
		&::before {
			content: '';
			position: absolute;
			bottom: 0;
			left: 0;
			width: 0%;
			height: 45%;
			transition: width .4s ease, background-color .8s ease;
			background-color: $pink;
			transform: rotate( -1deg );
		}
		&:hover {
			&::before {
				width: 100%;
				background-color: #fff;
				opacity: .6;
			}
		}
		span {
			position: relative;
			z-index: 1;
			font-weight: 700;
			font-size: responsive(30, 60);
		}
		b {
			position: relative;
			z-index: 1;
			font-weight: normal;
			font-size: responsive(14, 16);
			display: block;
			margin: 5px 0 0;
			line-height: 1.4;
		}
	}
	button {
		color: $blue;
		font-size: 50px;
		line-height: .5;
		transform: rotate(45deg);
		position: absolute;
		right: responsive(20, 40);
		top: responsive(20, 40);
	}
	&.active {
		right: 0%;
	}
}

canvas {
	position: absolute;
	width: 100%;
	height: 100vh;
	top: 0;
	left: 0;
}